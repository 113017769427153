<template>
  <div class="a-sign-container">
    <div class="a-nav-container">
      <a-logo></a-logo>
      <div></div>
    </div>
    <div class="a-tips-container">
      <div class="a-tips-msg">注册成功，等待管理员审核通过</div>
      <el-button type="text" size="small" @click="toHome">返回首页 ></el-button>
    </div>
  </div>
</template>

<script>
import aLogo from "@/layouts/components/Logo";
export default {
  data() {
    return {
      // activeIndex: this.$route.path,
    };
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
  },
  computed: {
    activeIndex() {
      return this.$route.path;
    },
  },
  components: {
    aLogo,
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
$nav-height: 48px;
.a-sign-container {
  .a-nav-container {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $nav-height;
    padding: 0 1rem;
    transform: translateX(-50%);
    background-color: transparent;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    .a-logo {
      padding: 0 1rem;
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
  .a-tips-container {
    width: 100%;
    margin-top: 18%;
    padding: 1rem;
    text-align: center;
    box-sizing: border-box;
    .a-tips-msg {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }
  }
}
</style>

